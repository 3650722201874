import React from 'react';
import './App.css';
import Header from './Header';
import Body from './Body';

function App() {
  return (

      <div className="App">
        <div>
          <Header/>
          <Body/>
        </div>
      </div>
  );
}

export default App;