import React from "react";
import './Body.css';
import "bootstrap/dist/css/bootstrap.min.css";
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Modal from '@material-ui/core/Modal';
import Contact from './Contact';
import closeIcon from './assets/close.svg';

require('dotenv').config();

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: '600px',
  bgcolor: 'background.paper',
  border: 'none',
  outline :'none !important',
  boxShadow: 24,
  p: 4,
  padding: 25
};

function Body() {

  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  return (
    <div>
      <Modal
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
              handleClose()
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="closeIcon text-right">
            <img src={closeIcon} alt="close" className="closeBtn" onClick={handleClose}/>
          </div>
          <Contact/>
        </Box>
      </Modal>
      <div className="banner">
        <div className="bannerImg"></div>
        <div className="content">
          <div className="heading">A New Era of Loan QC Powered by AI</div>
          <div className="desc">Helping you replace manual processes and accelerate your growth while maintaining accuracy and bolstering your compliance standards.</div>
          <Button type="button" variant="contained" className="requestBtn" onClick={handleOpen}>
            Request Demo
          </Button>
        </div>
      </div>
      <div className="mainContent">
        <div className="heading">A complete life-of-loan solution powered by AI and machine learning</div>
        <div className="desc">This innovative SaaS offering can help you automate document processing and key components of the loan manufacturing process including pre- and post-close quality control and service transfers.
          TrelixAI helps you eliminate repetitive tasks inherent in the loan review process providing you an efficient, time saving solution that can significantly reduce operating costs.
          Why use TrelixAI?
        </div>
      </div>
      <div className="colContent">
        <div className="row">
          <div className="col col-12 col-xs-12 col-md-12 col-lg-4">
            <h1 className="col col-xs-12 col-sm-8">Automate your business</h1>
            <div className="desc col col-xs-12 col-sm-10">TrelixAI uses smart OCR technology to auto classify both structured and unstructured documents from loan packages and detect and identify the initial and final version of these documents.
            TrelixAI’s powerful artificial intelligence and advanced machine learning capabilities can help you accurately perform “stare and compare” document reviews and significantly reduce the countless hours spent on these manual processes.</div>
          </div>
          <div className="col col-12 col-xs-12 col-md-12 col-lg-4">
            <h1 className="col-xs-12 col-sm-8">Built to fit your needs</h1>
            <div className="desc col col-xs-12 col-sm-10">In today’s environment, one size does not fit all. TrelixAI provides you complete flexibility to manage your workflow tasks, configure due diligence checklists and exception library to fit the evolving needs of your business.</div>
          </div>
          <div className="col col-12 col-xs-12 col-md-12 col-lg-4">
            <h1 className="col-xs-12 col-sm-8">The TrelixAI difference</h1>
            <div className="desc col col-xs-12 col-sm-10">Configurable data tape mapping including MISMO standards • Auto-classification of scanned image documents • Auto-extraction of key data fields • Auto-sampling for statistically significant assessments • Due Diligence checklists • Exception libraries • Automated business rules • Out-of-the-box third party integrations</div>
          </div>
        </div>
      </div>
      <div className="learnMoreContent">
        <div className="row pad160">
          <div className="col-12 col-xs-12 col-sm-7 col-md-8 col-lg-8 col-xl-9 learnText learnText">To learn more about TrelixAI and how we can help you automate the most time consuming processes of your business, click to schedule a demo!</div>
          <div className="col-12 col-xs-12 col-sm-5 col-md-4 col-lg-4 col-xl-3 learnCTA" onClick={handleOpen}><span>Request a Demo Today</span></div>
        </div>
      </div>
      <footer>
        IF YOU ARE USING A SCREEN READER AND ARE HAVING PROBLEMS USING THIS WEBSITE, PLEASE CALL (855) 350-7368 FOR ASSISTANCE.<br/><br/>
        © 2022 TRELIXAI. All Rights Reserved.
      </footer>
    </div>
  );
}





export default Body;