import React from "react";
import './Header.css';
import logo from './assets/logo.svg';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Modal from '@material-ui/core/Modal';
import Contact from './Contact';
import closeIcon from './assets/close.svg';

require('dotenv').config();

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: '600px',
  bgcolor: 'background.paper',
  border: 'none',
  outline :'none !important',
  boxShadow: 24,
  p: 4,
  padding: 25
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuButton: {
      marginRight: theme.spacing(2),
    }
  })
);

function Header() {

  
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  return (
    <div>
      <Modal
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
              handleClose()
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="closeIcon text-right">
            <img src={closeIcon} alt="close" className="closeBtn" onClick={handleClose}/>
          </div>
          <Contact/>
        </Box>
      </Modal>

      <div className="header">
        <div className="headerCont">
          <div className="logo">
            <img src={logo} alt="TrelixAI" />
          </div>
          <div className="navItem">
            <Button type="button" variant="contained" className="requestBtn" onClick={handleOpen}>
              Request Demo
            </Button>
            <div className="divider"></div>
            <IconButton edge="end" className={classes.menuButton} color="inherit" aria-label="menu">
              <MenuIcon />
            </IconButton>
          </div>
        </div>
      </div>
    </div>
  );
}





export default Header;