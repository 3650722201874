import React, { useEffect } from "react";
import './Contact.css';

require('dotenv').config();

declare const window: any;

function Contact() {
  

  useEffect(() => { 
    window.MktoForms2.loadForm("//pages.altisource.com", "270-SDM-131", 1528);
  }, []);

  // const [fn, setFn] = React.useState('');
  // const [fnError, setFnError] = React.useState(false);
  // const [ln, setLn] = React.useState('');
  // const [lnError, setLnError] = React.useState(false);
  // const [cn, setCn] = React.useState('');
  // const [cnError, setCnError] = React.useState(false);
  // const [email, setEmail] = React.useState('');
  // const [emailError, setEmailError] = React.useState(false);
  // const [phone, setPhone] = React.useState('');
  // const [phoneError, setPhoneError] = React.useState(false);
  // const [msg, setMsg] = React.useState('');
  // const [msgError, setMsgError] = React.useState(false);
  // const [saveLoad, setSaveLoad] = React.useState(false);
  // const [saveLoadError, setSaveLoadError] = React.useState(false);
  // const [successMsg, setSuccessMsg] = React.useState(false);
  
  // const validateFields = () => {
  //   let errorCount = 0;
  //   if(fn.trim() === '') {
  //     setFnError(true);
  //     errorCount++;
  //   }
  //   if(ln.trim() === '') {
  //     setLnError(true);
  //     errorCount++;
  //   }  
  //   if(cn.trim() === '') {
  //     setCnError(true);
  //     errorCount++;
  //   } 
  //   const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
  //   if( email.trim() === "") {
  //       setEmailError(true);
  //       errorCount++;
  //   } else if (!pattern.test(email.trim())) {
  //       setEmailError(true);
  //       errorCount++;
  //   }
  //   if(phone.trim() === '') {
  //       setPhoneError(true);
  //       errorCount++;
  //   } else if(phone.length !== 10) {
  //       setPhoneError(true);
  //       errorCount++;
  //   }
  //   if( msg.trim() === "") {
  //       setMsgError(true);
  //       errorCount++;
  //   }
  //   return errorCount;
  // }

  // const submitMsg = () => {
  //   setSaveLoadError(false);
  //   if( fnError || lnError || cnError || emailError || phoneError || msgError) {
  //     return;
  //   }

  //   let errorCount = validateFields();
  //   if( errorCount > 0 ) {
  //     return;
  //   }

  //   const input = {
  //    "firstName": fn,
  //    "lastName": ln,
  //    "companyName": cn,
  //    "email": email,
  //    "phone": phone,
  //    "message": msg
  //   }

  //   setSaveLoad(true);
  //     axios.post("https://od25bgvpn0.execute-api.us-east-1.amazonaws.com/Prod/contact-for-demo",input).then((res)=>{
  //         if(res.status === 200) {
  //           setSaveLoad(false);
  //           setSaveLoadError(false);
  //           setSuccessMsg(true);
  //         } else {
  //           setSaveLoad(false);
  //           setSaveLoadError(true);
  //         }
  //     }).catch(function (error) {
  //       setSaveLoad(false);
  //       setSaveLoadError(true);
  //     });
  // }

  return (
    <div className="modalBody">
      
      <form id="mktoForm_1528"></form>
      {/*{ successMsg ?
        <div className="succCont">Your Details Saved Succesfully. We will get back to you shortly.</div>
      :  
        <div>
          <div className="desc">To learn more about TrelixAI and how we can help you improve your loan quality please enter the following information:</div>
          <div className="formCont">
            <form autoComplete="off">
            <div className="row">
                <div className="col col-xs-12 col-md-6">
                  <TextField
                    id="fnField"
                    className="full-wd"
                    variant="outlined"
                    label="First Name*"
                    type="text"
                    value={fn}
                    error={fnError}
                    helperText={fnError?'Enter First Name':''}
                    onBlur={(e)=>{
                        if(e.target.value.trim() !== '') {
                            setFnError(false);
                        } else {
                            setFnError(true);
                        }
                    }}
                    onChange ={(e)=>{
                        if(e.target.value.trim() !== '') {
                            setFnError(false);
                        }
                        setFn(e.target.value);
                    }}
                  />
                </div>
                <div className="col col-xs-12 col-md-6">
                  <TextField
                    id="lnField"
                    className="full-wd"
                    variant="outlined"
                    label="Last Name*"
                    autoComplete="off"
                    type="text"
                    value={ln}
                    error={lnError}
                    helperText={lnError?'Enter Last Name':''}
                    onBlur={(e)=>{
                        if(e.target.value.trim() !== '') {
                            setLnError(false);
                        } else {
                            setLnError(true);
                        }
                    }}
                    onChange ={(e)=>{
                        if(e.target.value.trim() !== '') {
                          setLnError(false);
                        }
                        setLn(e.target.value);
                    }}
                  />
                </div>
            </div>
            <div className="row">
              <div className="col col-xs-12">
                  <TextField
                    id="compField"
                    className="full-wd"
                    variant="outlined"
                    label="Company Name*"
                    type="text"
                    value={cn}
                    error={cnError}
                    helperText={cnError?'Enter Company Name':''}
                    onBlur={(e)=>{
                        if(e.target.value.trim() !== '') {
                            setCnError(false);
                        } else {
                            setCnError(true);
                        }
                    }}
                    onChange ={(e)=>{
                        if(e.target.value.trim() !== '') {
                          setCnError(false);
                        }
                        setCn(e.target.value);
                    }}
                  />
                </div>  
            </div>
            <div className="row">
              <div className="col col-xs-12">
                  <TextField
                    id="emField"
                    className="full-wd"
                    variant="outlined"
                    label="Email*"
                    type="text"
                    value={email}
                    error={emailError}
                    helperText={emailError?'Enter Valid Email Id':''}
                    onBlur={(e)=>{
                        const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
                        if( e.target.value.trim() === "") {
                            setEmailError(true);
                        } else if (!pattern.test(e.target.value)) {
                            setEmailError(true);
                        } else {
                            setEmailError(false);
                        }
                    }}
                    onChange ={(e)=>{
                        if(e.target.value.trim() !== '') {
                          setEmailError(false);
                        }
                        setEmail(e.target.value);
                    }}
                  />
                </div>  
            </div>
            <div className="row">
              <div className="col col-xs-12">
                  <TextField
                    id="phField"
                    className="full-wd"
                    variant="outlined"
                    label="Phone*"
                    type="number"
                    value={phone}
                    error={phoneError}
                    helperText={phoneError?'Enter Valid Phone':''}
                    onBlur={(e)=>{
                        if(e.target.value.trim() === '') {
                            setPhoneError(true);
                        } else if(e.target.value.length !== 10 ) {
                            setPhoneError(true);
                        } else {
                            setPhoneError(false);
                        }
                    }}
                    onChange ={(e)=>{
                        if(e.target.value.trim() !== '') {
                          setPhoneError(false);
                        }
                        setPhone(e.target.value);
                    }}
                  />
                </div>  
            </div>
            <div className="row">
              <div className="col col-xs-12">
                  <TextField
                    id="msgField"
                    className="full-msg-wd"
                    variant="outlined"
                    label="How can TrelixAI help you?*"
                    type="text"
                    multiline={true}
                    minRows={4}
                    value={msg}
                    error={msgError}
                    helperText={msgError?'Enter Message':''}
                    onBlur={(e)=>{
                        if( e.target.value.trim() === "") {
                            setMsgError(true);
                        } else {
                            setMsgError(false);
                        }
                    }}
                    onChange ={(e)=>{
                        if(e.target.value.trim() !== '') {
                          setMsgError(false);
                        }
                        setMsg(e.target.value);
                    }}
                  />
              </div>  
            </div>
            <div className="row">
              <div className="col col-xs-12 rdCta">
                { saveLoad ?
                    <Spinner
                      animation="border"
                      variant="secondary"
                    />
                    :
                      <Button type="button" variant="contained" className="requestBtn" onClick={submitMsg}>
                        Request a Demo
                      </Button>
                    }
                    { saveLoadError ?
                      <div className="errorMsg">We are Facing Technical issue. Please try again later.</div>
                      :
                      ''
                    }
              </div>
            </div>
            </form>
          </div>
        </div>
      }*/}
      </div>
  );
}





export default Contact;